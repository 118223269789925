import React, { Component } from 'react';


import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import HomeOwlSlider from '../Element/HomeOwlSlider';
import HomeOwlSlider2 from '../Element/HomeOwlSlider2';
import ImgCarouselContent from '../Element/ImgCarouselContent';
import HomeTab from '../Element/HomeTab';
import Counter from '../Element/Counter';
import PortfolioCarousel from '../Element/PortfolioCarousel';
import TestimonialCarousel from '../Element/TestimonialCarousel';
import FormStyle from '../Element/FormStyle';
import RegisteredPlatform from '../Element/Section/RegisteredPlatform'

//Images
import pic4 from './../../assets/images/about/pic4.jpg';
import bgimg from './../../assets/images/background/bg6.jpg';
import bgimg2 from './../../assets/images/background/bg-map.jpg';
import bgimg3 from './../../assets/images/background/bg1.jpg';
import ChoseUs from '../Element/Section/ChoseUs';
import WarrantyServiceInformation from '../Element/Section/FooterInformation';
import ItemProduct from '../Element/ItemProduct';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { AboutUs } from '../../assets/data/company';
import config from '../../config/config';
import { SupabaseClient } from '../../db/db';
import { GetContent, GetRecommedationProducts } from '../../db/resource';


class Homepage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
            counterDisplay: [],
        }
    }
    async componentDidMount() {
        // const { data, error } = await SupabaseClient
        //     .from('products')
        //     .select()

        const dataProduct = await GetRecommedationProducts()
        const counterDisplay = await GetContent("counter_display")
        console.log("counterDisplay", counterDisplay)
        // if (error) {
        this.setState({
            products: dataProduct,
            counterDisplay: counterDisplay
        })
    }

    render() {
        return (
            <div className="page-wraper">
                <Header
                    title="Arstnesia - Designed Ground up to be `One Stop Solution` for International Trade Company"
                    metaDescription="Designed Ground up to be `One Stop Solution` for International Trade Company."
                />
                <div className="page-content bg-white">
                    {/*  Slider Banner */}
                    <div className="owl-slider-banner main-slider">
                        <HomeOwlSlider />
                        {/*  Service   */}
                        <div className="service-box-slide">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ImgCarouselContent />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  Service End  */}
                    </div>
                    {/*  Slider Banner */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full bg-white content-inner-1 about-us">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-7 col-md-8">
                                        <div className="about-box row" >
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">About Us<span className="bg-primary"></span></h2>
                                                <h4 className="text-gray-dark">We create unique experiences</h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>{AboutUs}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-4 about-img" >
                                        <img src={pic4} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- About Us End --> */}
                        {/* <!-- Our Services --> */}
                        {/* <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">Our Services</h4>
                                    <h2 className="box-title m-tb0">Web Development Services<span className="bg-primary"></span></h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                            </div>
                            <div className="development-box">
                                <div className="container">
                                    <HomeOwlSlider2 />
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- Our Services --> */}

                        <div className="section-full content-inner-1 mfp-gallery">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="box-title m-tb0">Our Products<span className="bg-primary"></span></h2>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        {this.state.products?.map((data, idx) => (
                                            <ItemProduct productName={data.name} productImage={data.image_thumbnail} cta={data.cta} keyName={data.slug} />
                                        ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="text-center text-black font-weight-bold m-5 text-decoration-underline align-items-center">
                                <a href='/product'> See All Products </a>
                            </div>
                        </div>

                        {/* <!-- Why Chose Us --> */}
                        <div className="section-full content-inner-1 overlay-primary choseus-tabs  bg-img-fix" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                            <div className="container">
                                <div className="section-head text-white text-center">
                                    <h2 className="box-title m-tb0">Why Choose Us<span className="bg-primary"></span></h2>
                                    <p>We are committed to sustainable and responsible business practices. We work with partners who share our values and strive to minimize the environmental impact of our operations.</p>
                                </div>
                            </div>
                            <ChoseUs />
                            <div className="choses-info text-white">
                                <Counter counterData={this.state.counterDisplay} />
                            </div>
                        </div>
                        {/* <!-- Why Chose Us End --> */}

                        {/* <!-- Testimonials --> */}
                        {/* <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgimg2 + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 ">
                                    <TestimonialCarousel />
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- Testimonials END --> */}



                        <RegisteredPlatform />

                        {/* <!-- Get in touch --> */}
                        {/* <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bgimg3 + ")" }}>
                            <FormStyle />
                        </div> */}
                        {/* <!-- Get in touch --> */}

                    </div>
                </div>
                <WarrantyServiceInformation />
                <Footer />

            </div>
        )
    }
}
export default Homepage;